import Field from "./Field";
import ForeignKey from "./ForeignKey";
export default function Table(){
    this.name="";
    this.fieldList=[];
    this.foreignKeyList=[];
    this.formattingData=function(){
      for(let field of this.fieldList){
        field.typeToUpperCase();
      }
      for(let foreignKey of this.foreignKeyList){
        foreignKey.associateToKey();
      }
    }
    this.check=function(){
      if(this.name==null||this.name==""){
        return "表名不能为空！";
      }else{
        for(let i=0;i<this.foreignKeyList.length;i++){
          //console.log("fn:"+this.foreignKeyList[i].referencedTableName);
          this.foreignKeyList[i].name=this.name+"_"+this.foreignKeyList[i].referencedTableName;
          let check=this.foreignKeyList[i].check();
          if(check!="pass"){
            return check;
          }
          for(let j=i+1;j<this.foreignKeyList.length;j++){
            if(this.foreignKeyList[i].name==this.name+"_"+this.foreignKeyList[j].referencedTableName){
              return "请勿引用重复表"
            }
          }
        }
        for(let i=0;i<this.fieldList.length;i++){
          let check=this.fieldList[i].check();
          if(check!="pass"){
            return check;
          }
          for(let j=i+1;j<this.fieldList.length;j++){
            if(this.fieldList[i].name==this.fieldList[j].name){
              return "不能出现重复列名！"
            }
          }
        }
        return "pass";
      }
    }
    this.clone=function(table){
      this.name=table.name;
      this.fieldList=[];
      for(var fieldItem of table.fieldList){
        var field=new Field();
        field.clone(fieldItem);
        this.fieldList.push(field);
      }
      for(var foreignKeyItem of table.foreignKeyList){
        var foreignKey=new ForeignKey();
        foreignKey.clone(foreignKeyItem);
        this.foreignKeyList.push(foreignKey);
      }
    };
}