import associate from "./associate";
export default function ForeignKey(){
  this.name="";
  this.fieldName="";
  this.referencedTableName="";
  this.referencedFieldName="";
  this.associate="";
  this.associateToKey=function(){
    for(let key in associate){
      if(associate[key]===this.associate){
        this.associate=key;
        return;
      }
    }
  }
  this.check=function(){
    if(this.fieldName==""){
      return "请选择字段";
    }else if(this.referencedTableName==""){
      return "请选择被引用的表"
    }else if(this.referencedFieldName==""){
      return "请选择被引用的字段"
    }else{
      return "pass";
    }
  }
  this.clone=function(foreignKey){
    this.name=foreignKey.name;
    this.fieldName=foreignKey.fieldName;
    this.referencedFieldName=foreignKey.referencedFieldName;
    this.referencedTableName=foreignKey.referencedTableName;
    this.associate=foreignKey.associate;
  }
}