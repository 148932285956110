<template>
  <div class="content">
    <div class="left">
      <div class="logo">
        <span class="iconfont lh-icon-logo"></span>
      </div>
      <div>
        <lh-button icon="lh-icon-save" @click="save()">保存全部</lh-button>
        <lh-button icon="lh-icon-add" @click="addProject()">新建项目</lh-button>
      </div>
      <!-- 项目弹窗 -->
      <lh-dialog :visible.sync="projectDialog">
        <table>
          <tr>
            <td>项目名称：</td>
            <td><lh-input v-model="dialogProject.name" placeholder="请输入项目名称"/></td>
          </tr>
          <tr>
            <td>包名：</td>
            <td><lh-input v-model="dialogProject.basePackage" placeholder="请输入包名"/></td>
          </tr>
        </table>
        <div slot="footer">
          <lh-button @click="projectDialog=false">取消</lh-button>
          <lh-button @click="projectDialogConfirm()">确定</lh-button>
        </div>
      </lh-dialog>
      <div class="project" v-for="(project,index) of projectList" :key="project.name" @click="currentProjectIndex=index">
        <lh-tip :text="project.basePackage" placement="top-left">
          <span :style="{borderLeft:index==currentProjectIndex?'2px solid #409EFF':'none'}">
            {{ project.name }}
          </span>
        </lh-tip>
        <div v-if="index==currentProjectIndex">
          <lh-button fontSize="10px" padding="3px" @click="updateProject()" icon="lh-icon-update">修改</lh-button>
          <lh-button fontSize="10px" padding="3px" @click="deleteProject()" icon="lh-icon-delete" backgroundColor="red">删除</lh-button>
        </div>
      </div>
    </div>
    <div class="middle" v-if="projectList.length>0">
      <h3>项目表：</h3>
      <div>
        <lh-button icon="lh-icon-add" @click="addTable()">新增表</lh-button>
        <lh-button icon="lh-icon-submit" @click="submit()" :loading="loading">提交生成</lh-button>
      </div>
      <lh-collapse v-for="(tableItem,index) of projectList[currentProjectIndex].tableList" :key="tableItem.name+index">
        <div slot="title">
          <span>{{ tableItem.name }}</span>
          <lh-button fontSize="10px" @click.stop="updateTable(index)" icon="lh-icon-update">修改</lh-button>
          <lh-button fontSize="10px" @click.stop="deleteTable(index)" icon="lh-icon-delete" backgroundColor="red">删除</lh-button>
        </div>
        <div>
          <lh-table>
            <lh-tr>
              <lh-th>序号</lh-th>
              <lh-th>列名</lh-th>
              <lh-th>类型</lh-th>
              <lh-th>长度</lh-th>
              <lh-th>是否可空</lh-th>
              <lh-th>是否唯一</lh-th>
              <lh-th>是否为主键</lh-th>
            </lh-tr>
            <lh-tr v-for="(fieldItem,index) of tableItem.fieldList" :key="fieldItem.name">
              <lh-td>{{ index+1 }}</lh-td>
              <lh-td>{{ fieldItem.name }}</lh-td>
              <lh-td>{{ fieldItem.type }}</lh-td>
              <lh-td>{{ fieldItem.size }}</lh-td>
              <lh-td><lh-checkbox disabled v-model="fieldItem.allowNull"/></lh-td>
              <lh-td><lh-checkbox disabled v-model="fieldItem.isUnique"/></lh-td>
              <lh-td><lh-checkbox disabled v-model="fieldItem.isPrimaryKey"/></lh-td>
            </lh-tr>
          </lh-table>
          <lh-empty size="100px" v-if="tableItem.fieldList.length==0">空空如也</lh-empty>
        </div>

        <lh-collapse>
          <div slot="title">外键联系</div>
          <lh-table>
            <lh-tr>
              <lh-th>序号</lh-th>
              <lh-th>外键名</lh-th>
              <lh-th>字段</lh-th>
              <lh-th>被引用的表</lh-th>
              <lh-th>被引用的字段</lh-th>
              <lh-th>联系</lh-th>
            </lh-tr>
            <lh-tr v-for="(foreignKeyItem,index) of tableItem.foreignKeyList" :key="foreignKeyItem.name">
              <lh-td>{{ index+1 }}</lh-td>
              <lh-td>{{ foreignKeyItem.name }}</lh-td>
              <lh-td>{{ foreignKeyItem.fieldName }}</lh-td>
              <lh-td>{{ foreignKeyItem.referencedTableName }}</lh-td>
              <lh-td>{{ foreignKeyItem.referencedFieldName }}</lh-td>
              <lh-td>{{ foreignKeyItem.associate }}</lh-td>
            </lh-tr>
          </lh-table>
          <lh-empty size="100px" v-if="tableItem.foreignKeyList.length==0">空空如也</lh-empty>
        </lh-collapse>
      </lh-collapse>
      <lh-empty size="100px" v-if="projectList[currentProjectIndex].tableList.length==0">空空如也</lh-empty>
      <!-- 表格弹窗 -->
      <lh-dialog width="80%" :visible.sync="tableDialog">
        <div style="height:400px">
          <div class="tableName">
            <div style="margin-right: 15px;">表名：<lh-input v-model="dialogTable.name" placeholder="请输入表名"/></div>
            <lh-radio v-model="select" radioValue="w">字段</lh-radio>
            <lh-radio v-model="select" radioValue="f">外键</lh-radio>
          </div>
          <div>
            <lh-button icon="lh-icon-add" v-if="select=='w'" @click="addField()">添加字段</lh-button>
            <lh-button icon="lh-icon-add" v-if="select=='f'" @click="addForeignKey()">添加外键联系</lh-button>
          </div>
          <lh-table width="100%" v-if="dialogTable&&select=='w'">
            <lh-tr>
              <lh-th>序号</lh-th>
              <lh-th>列名</lh-th>
              <lh-th>类型</lh-th>
              <lh-th>长度</lh-th>
              <lh-th>是否可空</lh-th>
              <lh-th>是否唯一</lh-th>
              <lh-th>是否为主键<lh-checkbox-controller name="isPrimaryKey" :min="1" :max="1" v-show="false"></lh-checkbox-controller></lh-th>
              <lh-th>操作</lh-th>
            </lh-tr>
            <template v-for="(fieldItem,index) of dialogTable.fieldList">
              <lh-tr v-show="(parseInt(index/pageSize)+1)==currentPageNum" :zIndex="dialogTable.fieldList.length-index" :key="'c'+index">
                <lh-td>{{ index+1 }}</lh-td>
                <lh-td><lh-input v-model="fieldItem.name"/></lh-td>
                <lh-td>
                  <lh-select v-model="fieldItem.type">
                    <lh-option v-for="typeItem of typeList" :value="typeItem" :key="'e'+typeItem">{{ typeItem }}</lh-option>
                  </lh-select>
                </lh-td>
                <lh-td><lh-input v-model="fieldItem.size" type="number"/></lh-td>
                <lh-td><lh-checkbox :disabled="fieldItem.isPrimaryKey?(fieldItem.allowNull=false)||true:false" v-model="fieldItem.allowNull"/></lh-td>
                <lh-td><lh-checkbox :disabled="fieldItem.isPrimaryKey?(fieldItem.isUnique=true)||true:false" v-model="fieldItem.isUnique"/></lh-td>
                <lh-td><lh-checkbox name="isPrimaryKey" v-model="fieldItem.isPrimaryKey"/></lh-td>
                <lh-td><lh-button icon="lh-icon-delete" backgroundColor="red" @click="deleteField(index)">删除</lh-button></lh-td>
              </lh-tr>
            </template>
          </lh-table>

          <lh-table width="100%" v-if="dialogTable&&select=='f'">
            <lh-tr>
              <lh-th>序号</lh-th>
              <lh-th>外键名</lh-th>
              <lh-th>字段</lh-th>
              <lh-th>被引用的表</lh-th>
              <lh-th>被引用的字段</lh-th>
              <lh-th>联系</lh-th>
              <lh-th>操作</lh-th>
            </lh-tr>
            <template v-for="(foreignKeyItem,index) of dialogTable.foreignKeyList">
              <lh-tr v-if="(parseInt(index/pageSize)+1)==currentPageNum" :zIndex="dialogTable.fieldList.length-index" :key="'c'+index">
                <lh-td>{{ index+1 }}</lh-td>
                <lh-td>{{ dialogTable.name+"_"+foreignKeyItem.referencedTableName }}</lh-td>
                <lh-td>
                  <lh-select v-model="foreignKeyItem.fieldName">
                    <lh-option v-for="fieldItem of dialogTable.fieldList" :value="fieldItem.name" :key="fieldItem.name">{{ fieldItem.name }}</lh-option>
                  </lh-select>
                </lh-td>
                <lh-td>
                  <lh-select v-model="foreignKeyItem.referencedTableName">
                    <template v-for="tableItem of projectList[currentProjectIndex].tableList">
                      <lh-option v-if="tableItem.name!=dialogTable.name" :key="tableItem.name" :value="tableItem.name" >{{ tableItem.name }}</lh-option>
                    </template>
                  </lh-select>
                </lh-td>
                <lh-td>
                  <lh-select v-model="foreignKeyItem.referencedFieldName">
                    <template v-if="projectList[currentProjectIndex].getTableByName(foreignKeyItem.referencedTableName)">
                      <lh-option v-for="fieldItem of projectList[currentProjectIndex].getTableByName(foreignKeyItem.referencedTableName).fieldList" :value="fieldItem.name" :key="fieldItem.name">{{ fieldItem.name }}</lh-option>
                    </template>
                  </lh-select>
                </lh-td>
                <lh-td>
                  <lh-select v-model="foreignKeyItem.associate">
                    <lh-option v-for="(value,key) in associate" :value="value" :key="key">{{ value }}</lh-option>
                  </lh-select>
                </lh-td>
                <lh-td><lh-button icon="lh-icon-delete" backgroundColor="red" @click="deleteForeignKey(index)">删除</lh-button></lh-td>
              </lh-tr>
            </template>
          </lh-table>
          <lh-empty size="100px" v-if="(select=='w'&&dialogTable.fieldList.length==0)||(select=='f'&&dialogTable.foreignKeyList.length==0)">空空如也</lh-empty>
          <div class="pagination">
            <lh-pagination v-if="dialogTable&&select=='w'"
            v-model="currentPageNum"
            :total="dialogTable.fieldList.length"
            :pageSize="pageSize"/>
            <lh-pagination v-else
            v-model="currentPageNum"
            :total="dialogTable.foreignKeyList.length"
            :pageSize="pageSize"/>
          </div>
        </div>
        <div slot="footer">
          <lh-button @click="tableDialog=false" margin="10px">取消</lh-button>
          <lh-button @click="tableDialogConfirm()" margin="10px">确认</lh-button>
        </div>
      </lh-dialog>
      <h3>生成代码：</h3>
      <code-view v-model="projectCode"/>
    </div>
    <div class="right">
        <load-table-list @getTableList="importTableList"/>
      </div>
  </div>
</template>

<script>
import Project from "./class/Project";
import Table from "./class/Table";
import Field from "./class/Field";
//import ForeignKey from "./class/ForeignKey";
import associate from "./class/associate";
import typeList from "./class/typeList";
import ForeignKey from './class/ForeignKey';
import CodeView from './components/CodeView.vue';
import request from '../../utils/request';
import LoadTableList from './components/LoadTableList.vue';
export default {
  components: { CodeView, LoadTableList},
  name:"CodeGenerate",
  data(){
    return {
      //visible:false,
      select:"w",
      projectList:[],
      //addProjectDialog:false,
      //newProject:new Project(),
      tableDialog:false,
      projectDialog:false,
      dialogTable:new Table(),
      dialogProject:new Project(),
      currentProjectIndex:0,
      currentTableIndex:0,
      associate:associate,
      operate:"add",
      temp:"",
      typeList:typeList,
      loading:false,
      projectCode:null,
      currentPageNum:1,
      pageSize:4
    }
  },
  mounted(){
    //this.dialogTable.fieldList.push(new Field());
    // this.dialogTable=new Table();
    // this.dialogTable.fieldList.push(new Field());
    let localProjectList=JSON.parse(localStorage.getItem("projectList"));
    if(localProjectList!=null){
      for(let projectItem of localProjectList){
        let project=new Project();
        project.clone(projectItem);
        this.projectList.push(project);
      }
    }
  },
  methods:{
    addProject:function(){
      // request.get("ssmCode/test",null).then(()=>{

      // }).catch((err)=>{
      //   console.log(err);
      // });
      this.operate="add";
      this.dialogProject=new Project();
      this.projectDialog=true;
    },
    updateProject:function(){
      this.operate="update";
      this.dialogProject=new Project();
      this.dialogProject.clone(this.projectList[this.currentProjectIndex]);
      this.projectDialog=true;
    },
    deleteProject:function(){
      this.$lhMessageBox("项目删除","确认删除此项目？").then(()=>{
        this.projectList.splice(this.currentProjectIndex,1);
        this.currentProjectIndex=0;
        this.save();
      })
    },
    projectDialogConfirm:function(){
      let check=this.dialogProject.check();
      if(check!="pass"){
        this.$lhMessage("warn",check);
        return;
      }
      if(this.operate=="add"){
        this.projectList.push(this.dialogProject);
      }else{
        this.projectList[this.currentProjectIndex]=this.dialogProject;
      }
      this.projectDialog=false;
      this.save();
    },
    addTable:function(){
      this.operate="add";
      this.dialogTable=new Table();
      this.tableDialog=true;
    },
    updateTable:function(index){
      this.operate="update";
      this.currentTableIndex=index;
      this.dialogTable=new Table();
      this.dialogTable.clone(this.projectList[this.currentProjectIndex].tableList[index]);
      this.tableDialog=true;
    },
    deleteTable:function(index){
      this.$lhMessageBox("删除表格","确认删除此表格").then(()=>{
        this.projectList[this.currentProjectIndex].tableList.splice(index,1);
        this.save();
      })
    },
    tableDialogConfirm:function(){
      let project=new Project();
      project.clone(this.projectList[this.currentProjectIndex]);
      if(this.operate=="add"){
        this.projectList[this.currentProjectIndex].tableList.push(this.dialogTable);
      }else if(this.operate=="update"){
        this.projectList[this.currentProjectIndex].tableList[this.currentTableIndex]=this.dialogTable;
      }
      let check=this.projectList[this.currentProjectIndex].check();
      //console.log("ch",check);
      if(check!="pass"){
        //console.log("check",check);
        this.projectList[this.currentProjectIndex]=project;
        this.$lhMessage("warn",check);
        return;
      }
      this.tableDialog=false;
      this.save();
    },
    save:function(){
      if(this.projectList.length!=0){
        let check=this.projectList[this.currentProjectIndex].check();
        if(check!="pass"){
          this.$lhMessage("warn",check);
          return;
        }
      }
      localStorage.setItem("projectList",JSON.stringify(this.projectList));
    },
    addField:function(){
      this.dialogTable.fieldList.push(new Field());
    },
    deleteField:function(index){
      this.dialogTable.fieldList.splice(index,1);
    },
    addForeignKey:function(){
      this.dialogTable.foreignKeyList.push(new ForeignKey());
    },
    deleteForeignKey:function(index){
      this.dialogTable.foreignKeyList.splice(index,1);
    },
    submit:function(){
      let check=this.projectList[this.currentProjectIndex].check();
      if(check!="pass"){
        this.$lhMessage("warn",check);
        return;
      }
      this.loading=true;
      let requestProject=new Project();
      requestProject.clone(this.projectList[this.currentProjectIndex]);
      requestProject.formattingData();
      request.post("ssmCode/getSsmCode",requestProject).then((res)=>{
        //console.log(res);
        this.projectCode=res.data;
      }).finally(()=>{
        this.loading=false;
      })
      // request.geta("ssmCode/test",{type:"varchar"}).then((res)=>{
      //   console.log(res);
      // }).finally(()=>{
      //   this.loading=false;
      // })
    },
    importTableList:function(tableList){
      //console.log("tableList");
      //console.log(tableList);
      if(this.projectList.length==0){
        this.$lhMessage("error","当前没有项目，请新建项目后导入");
        return;
      }
      for(let tableItem of tableList){
        this.projectList[this.currentProjectIndex].tableList.push(tableItem);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content{
  width: 100%;
  display: flex;
  justify-content: space-around;
  //border: solid;
  //height: 100vh;
  .left{
    //width:50%;
    //border: solid;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo{
      padding: 20px;
      .lh-icon-logo{
        font-size: 130px;
        color: #409EFF;
      }
    }

    .project{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
      padding: 6px;
      margin: 3px;
      cursor: pointer;
      span{
        padding-left: 6px;
      }
    }
  }

  

  // .footer{
  //   display: flex;
  //   justify-content: flex-end;
  // }

  .middle{
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: flex-start;
    .pagination{
      display: flex;
      justify-content: center;
      padding:10px
    }
    //border: solid;
    .tableName{
      padding: 10px;
      display: flex;
    }
  }
}
</style>