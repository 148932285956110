export default function Field(){
    this.name="";
    this.type="";
    this.size=0;
    this.allowNull=false;
    this.isPrimaryKey=false;
    this.isUnique=false;
    this.typeToUpperCase=function(){
      this.type=this.type.toUpperCase();
    }
    this.check=function(){
      if(this.name==null||this.name==""){
        return "列名不能为空！";
      }else if(this.type==null||this.type==""){
        return "请选择数据类型！";
      }else{
        return "pass";
      }
    }
    this.clone=function(field){
      this.name=field.name;
      this.type=field.type;
      this.size=field.size;
      this.allowNull=field.allowNull;
      this.isPrimaryKey=field.isPrimaryKey;
      this.isUnique=field.isUnique;
    }
}