<template>
  <div class="codeView">
    <lh-button @click="downloadAll()">下载全部</lh-button>
    <template v-if="projectCode">
    <lh-collapse>
        <div slot="title">pojo</div>
        <lh-collapse v-for="codeFile of projectCode.pojo" :key="codeFile.name">
            <div slot="title">{{codeFile.name}}</div>
            <lh-code-editor :copyButton="true" :downloadButton="true" langluage="java" maxWidth="500px" :fileName="codeFile.name+'.java'" v-model="codeFile.code"/>
        </lh-collapse>
    </lh-collapse>
    <lh-collapse>
        <div slot="title">entity</div>
        <lh-collapse v-for="codeFile of projectCode.entity" :key="codeFile.name">
            <div slot="title">{{codeFile.name}}</div>
            <lh-code-editor :copyButton="true" :downloadButton="true" langluage="java" maxWidth="500px" :fileName="codeFile.name+'.java'" v-model="codeFile.code"/>
        </lh-collapse>
    </lh-collapse>
    <lh-collapse>
        <div slot="title">mapper</div>
        <lh-collapse v-for="codeFile of projectCode.mapper" :key="codeFile.name">
            <div slot="title">{{codeFile.name}}</div>
            <lh-code-editor :copyButton="true" :downloadButton="true" langluage="java" maxWidth="500px" :fileName="codeFile.name+'.java'" v-model="codeFile.code"/>
        </lh-collapse>
    </lh-collapse>
    <lh-collapse>
        <div slot="title">mapperXml</div>
        <lh-collapse v-for="codeFile of projectCode.mapperXml" :key="codeFile.name">
            <div slot="title">{{codeFile.name}}</div>
            <lh-code-editor :copyButton="true" :downloadButton="true" langluage="java" maxWidth="500px" :fileName="codeFile.name+'.xml'" v-model="codeFile.code"/>
        </lh-collapse>
    </lh-collapse>
    <lh-collapse>
        <div slot="title">service</div>
        <lh-collapse v-for="codeFile of projectCode.service" :key="codeFile.name">
            <div slot="title">{{codeFile.name}}</div>
            <lh-code-editor :copyButton="true" :downloadButton="true" langluage="java" maxWidth="500px" :fileName="codeFile.name+'.java'" v-model="codeFile.code"/>
        </lh-collapse>
    </lh-collapse>
    <lh-collapse>
        <div slot="title">controller</div>
        <lh-collapse v-for="codeFile of projectCode.controller" :key="codeFile.name">
            <div slot="title">{{codeFile.name}}</div>
            <lh-code-editor :copyButton="true" :downloadButton="true" langluage="java" maxWidth="500px" :fileName="codeFile.name+'.java'" v-model="codeFile.code"/>
        </lh-collapse>
    </lh-collapse>
    </template>
    <lh-collapse>
        <div slot="title">example</div>
        <lh-code-editor :copyButton="true" :downloadButton="true" langluage="java" readonly maxWidth="500px" fileName="Example.java" v-model="exampleCode"/>
    </lh-collapse>
    
    <!-- <lh-collapse>
        <div slot="title">pojo</div>
        <lh-collapse>
            <div slot="title">Test1</div>
            <lh-code-editor maxWidth="500px" v-model="code"/>
        </lh-collapse>
    </lh-collapse> -->
    <!-- <lh-collapse>
        <div slot="title">mapper</div>
        <div>
            <lh-code-editor maxWidth="500px" language="java" v-model="codeObjectData.mapper.code"/>
        </div>
    </lh-collapse> -->
    <!-- <img src="/file/temp.jpg"/> -->
  </div>
</template>

<script>
import JSZip from "jszip";
export default {
    name:"CodeView",
    data(){
        return {
            projectCode:null,
            exampleCode:""
        }
    },
    props:{
        value:{
            type:Object,
            default:function(){
                return {
                    pojo:[],
                    entity:[],
                    mapper:[],
                    mapperXml:[],
                    service:[],
                    controller:[]
                }
            }
        }
    },
    watch:{
        value:{
            immediate:true,
            handler(newVal){
                //console.log("newVal:")
                //console.log(newVal);
                this.projectCode=newVal;
            }
        },
        projectCode:{
            immediate:false,
            handler(newVal){
                this.$emit("input",newVal);
            }
        }
    },
    methods:{
        downloadAll:function(){
            let zip=new JSZip();
            if(this.projectCode!=null){
                for(let codeFile of this.projectCode.pojo){
                    zip.folder("pojo").file(codeFile.name+".java",codeFile.code);
                }

                for(let codeFile of this.projectCode.entity){
                    zip.folder("entity").file(codeFile.name+".java",codeFile.code);
                }

                for(let codeFile of this.projectCode.mapper){
                    zip.folder("mapper").file(codeFile.name+".java",codeFile.code);
                }

                for(let codeFile of this.projectCode.mapperXml){
                    zip.folder("mapperXml").file(codeFile.name+".xml",codeFile.code);
                }

                for(let codeFile of this.projectCode.service){
                    zip.folder("service").file(codeFile.name+".java",codeFile.code);
                }

                for(let codeFile of this.projectCode.controller){
                    zip.folder("controller").file(codeFile.name+".java",codeFile.code);
                }
            }
            zip.folder("example").file("Example.java",this.exampleCode);
            
            //zip.folder("generateCode").folder("entity");
            //zip.file("test.txt", "shendi").folder("test").file("test.txt", "shendi");
            zip.generateAsync({type:"blob"}).then(function (base64) {
                var tmp = document.createElement('a');
                tmp.download = "generateCode.zip";
                tmp.style.display = 'none';
                tmp.href = URL.createObjectURL(base64);
                document.body.appendChild(tmp);
                tmp.click();
                document.body.removeChild(tmp);
            });
        },
        // 读取文件
        loadFile:function(name) {
            const xhr = new XMLHttpRequest();
            const okStatus = document.location.protocol === 'file:' ? 0 : 200;
            xhr.open('GET', name, false);
            xhr.overrideMimeType('text/html;charset=utf-8');
            // 默认为utf-8
            xhr.send(null);
            return xhr.status === okStatus ? xhr.responseText : null;
        },
        // unicode转utf-8
        unicodeToUtf8:function(data) {
            data = data.replace(/\\/g, '%');
            return unescape(data);
        }
    },

    mounted(){
        // let files=require.context("../../../static/", true, /\.txt$/);
        // let fileReader=new FileReader();
        // // let example=require("../../../assets/temp.txt");
        // // //console.log(example);
        // // // let file=new File("../../../assets/Example.txt");
        // console.log(files[0]);
        // fileReader.readAsText(files[0],"UTF-8");
        // fileReader.onload=(evt)=>{
        //     console.log("example");
        //     console.log(evt);
        // }
        
        let path="/static/";
        // if(process.env.NODE_ENV=="development"){
        //     path="/static/";
        // }else{
        //     path="/index/static/";
        // }

        const file = this.loadFile(path+'Example.java');
        let text=this.unicodeToUtf8(file);
        this.$nextTick(()=>{
            this.exampleCode=text;
        })

        //this.exampleCode="cscs";
        
        //console.log(this.exampleCode);
    }

}
</script>

<style lang="less" scoped>
.codeView{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
</style>