import Table from "./Table";
export default function Project(){
    this.name="";
    this.basePackage="";
    this.tableList=[];
    this.formattingData=function(){
      for(let table of this.tableList){
        table.formattingData();
      }
    }
    this.check=function(){
      if(this.name===""||this.name==null){
        return "项目名不能为空！";
      }else if(this.basePackage===""||this.basePackage==null){
        return "请输入包名！";
      }else{
        for(var i=0;i<this.tableList.length;i++){
          let check=this.tableList[i].check();
          if(check!="pass"){
            return check;
          }
          for(var j=i+1;j<this.tableList.length;j++){
            if(this.tableList[i].name==this.tableList[j].name){
              return "不能出现重复表名!";
            }
          }
        }
        return "pass";
      }
    }
    this.getTableByName=function(tableName){
      for(var item of this.tableList){
        if(item.name==tableName){
          return item;
        }
      }
      return null;
    }
    this.clone=function(project){
      this.name=project.name;
      this.basePackage=project.basePackage;
      this.tableList=[];
      for(var item of project.tableList){
        var temp=new Table();
        temp.clone(item);
        this.tableList.push(temp);
      }
    }
}